@import "../../../constants.scss";

.wrapper {
  width: 100%;
  max-width: 100%;
  // width: 1050px !important;
  // min-width: 1000px;
  border: 2px solid $mainColor;
  // td {
  //   background-color: powderblue;
  // }
  :global(.functions) {
    display: flex;
    width: 100%;
    gap: 40px;
    :global(.item) {
      display: flex;
      justify-content: space-between;
    }
  }
  input:global(.number-input) {
    width: 40px;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    border: 1px solid gray;
    &:focus {
      border: 1px solid $SecondaryBlue;
      outline: none;
    }
  }
}
