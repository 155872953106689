@import "../../../constants.scss";

.quantity {
  height: 28px;
  width: 28px;
  font-size: 20px;
  padding: 5px;
  margin-left: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  background-color: $oppositeColor;
}
