.id {
  display: flex;
  width: 400px;
  height: 100%;
  align-items: center;
  min-width: 100px;
  min-height: 20px;
  .idPlaceholder {
    color: #bbb;
    display: none;
    width: 100%;
  }
  @media (max-width: 768px) {
    .idPlaceholder {
      display: inline;
    }
  }
}
