@import "../../constants.scss";

.wrapper {
  padding: 3rem 10px 0 10px;
  .buttons {
    min-width: 100px;
  }
  .eventData {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    // :global(.ant-select) {
    //   width: 300px;
    // }
    // :global(.ant-picker) {
    //   width: 200px;
    //   flex-grow: grow;
    // }
    .eventName {
      max-width: 300px;
      min-width: 240px;
    }
  }
  padding-bottom: 20px;
  .message {
    margin-top: 1rem;
    font-size: $defaultFont;
  }
  .tableTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// @media (max-width: 768px) {
//   .wrapper {
//     .eventData {
//       flex-wrap: nowrap;
//       :global(.ant-select.ant-select-single.ant-select-show-arrow) {
//         // width: 60%;
//         flex-grow: 1;
//       }
//       :global(.ant-picker) {
//         width: 200px;
//       }
//     }
//     :global(.table-buttons) {
//       flex-wrap: wrap;
//     }
//   }
// }
// @media (max-width: 450px) {
//   .wrapper {
//     .eventData {
//       flex-wrap: wrap;
//       :global(.ant-select.ant-select-single.ant-select-show-arrow) {
//         width: 100%;
//         min-width: 100%;
//       }
//       :global(.ant-select-item.ant-select-item-option) {
//         width: 100%;
//         min-width: 200px;
//       }
//       :global(.ant-picker) {
//         width: 100%;
//       }
//     }
//   }
// }
