.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: peru;
  padding: 2rem 10px 0 10px;
  .form {
    width: 70%;
    max-width: 800px;
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .event {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 1em;
    padding-right: 60px;
    .eventName {
      flex: 1 0 240px;
    }
  }

  .reason {
    display: flex;
    margin-bottom: 1rem;
  }
  .error {
    color: red;
  }
  .submitButton {
    margin-top: 10px;
    &:focus {
      background-color: red;
    }
  }
}
.confirm {
  width: 300px;
}
.restore {
  margin-top: 1em;
}
