@import "/src/constants.scss";

.wrapper {
  width: 100%;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  .danger {
    height: 24px;
    font-size: 16px;
    color: $Red;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 0;
  }
  main {
    max-width: 500px;
    text-align: center;
    padding: 0px 0px 80px 0px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #ddd;
    color: #777;
    @media (max-width: 576px) {
      width: 100%;
      max-width: unset;
      border-radius: none;
      box-shadow: none;
    }
    .logo {
      width: 400px;
      padding: 20px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: $SecondaryBlue;
      @media (max-width: 576px) {
        width: 100%;
        padding: 20px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
      // svg {
      //   width: 60px;
      //   fill: none;
      //   stroke: white;
      //   stroke-width: 4px;
      //   stroke-dasharray: 650px;
      //   stroke-dashoffset: 650;
      //   fill: none;
      //   animation: line-anim 5s ease forwards 1s, fill 2s ease forwards 4s;
      // }

      img {
        width: 50px;
        height: 50px;
      }
    }
    section {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      h1 {
        font-size: 2rem;
        color: #444;
      }
    }
    form {
      text-align: left;
      width: 290px;
      font-size: 1rem;
      .input {
        margin: 20px 0;
        position: relative;
      }
      label {
        text-align: left;
      }
      label.login {
        position: relative;
        &:before {
          position: absolute;
          top: 28px;
          left: 10px;
          content: "A";
          display: inline;
          visibility: visible;
        }
      }
      input {
        width: 100%;
        border: 1px solid #c9d0d6;
        border-radius: 5px;
        outline: none;
        padding: 5px 10px;
        font-size: 1rem;
        // &:hover,
        &:focus {
          border-color: $SecondaryBlue;
        }
      }
      input.login {
        padding-left: 25px;
      }

      .eye {
        cursor: pointer;
        position: absolute;
        font-size: 16px;
        right: 12px;
        top: 30px;
      }
    }
    button.submit {
      display: block;
      width: 290px;
      margin-top: 40px;
      padding: 10px;
      cursor: pointer;
      border: none;
      border-radius: 10px;
      background-color: $Red;
      color: $White;
      font-size: 1.25rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $Blue;
        border: none;
        transition: all 0.3s ease-in-out;
      }
      &:disabled {
        background-color: $Blue;
        border: none;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .spinner {
    width: 100%;
    min-height: 60px;
    width: 290px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}
.remind {
  margin-top: 1rem;
}
.remind:hover a,
.remind:focus a {
  color: rgba(66, 170, 255, 0.656);
  text-decoration: underline;
}
