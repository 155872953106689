@import "../../constants.scss";

*,
::before,
::after {
    box-sizing: border-box;
}

html {
    font-size: 1rem;
    display: flex;
    justify-content: center;
}

body {
    font-family: "Roboto Regular", "Arial", sans-serif;
    padding: 0;
    margin: 0 auto;
    background-color: #fff;
    max-width: 1200px;
    display: flex;
    justify-content: center;
}
.main-container {
    width: 100%;
    padding-bottom: 2em;
}
// @media (max-width: 1220px) {
//   .main-container {
//     padding-left: 10px;
//     padding-right: 10px;
//   }
// }

#root {
    width: 100%;
    margin-bottom: 50px;
}

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}
button {
    border: none;
    padding: 0.5em 1em;
    cursor: pointer;
}
// Таблицы
table.results-table {
    // max-width: 100%;
    // min-width: 800px;
    border: 1px solid $mainColor;
    tr {
        .current-row {
            border: 2px solid $mainColor;
        }
        th {
            background-color: $lightColor;
            border: 1px solid $secondaryColor;
            color: $mainColor;
        }
        td {
            border: 1px solid $secondaryColor;
            padding-left: 10px;
        }
    }
}

// buttons

.table-buttons {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    gap: 10px;
    flex-wrap: wrap;
}
.ant-btn.ant-btn-primary.submit-button {
    background-color: $mainColor;
    border: 1px solid $mainColor;
    &:hover {
        color: $mainColor;
        background-color: transparent;
    }
}
.ant-btn.ant-btn-primary {
    background-color: $oppositeColor;
    border: 1px solid $oppositeColor;
    font-weight: 700;
    &:hover {
        background-color: lighten($oppositeColor, 10%);
    }
}
.ant-btn.ant-btn-default {
    &:hover {
        border: 1px solid $oppositeColor;
        color: $oppositeColor;
    }
}

.app-tooltip {
    position: relative;
    padding: 0px;
    .tooltiptext {
        visibility: hidden;
        max-width: 200px;
        background-color: $Blue;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 150%;
        right: 0px;
    }
    @media (hover: hover) {
        &:hover {
            &:not(:active) .tooltiptext {
                animation: tooltip-animate 4s;
            }
        }
    }

    @media (hover: none) {
        &:hover {
            .tooltiptext {
                visibility: hidden;
                animation: none;
            }
        }
    }

    @keyframes tooltip-animate {
        0% {
            visibility: hidden;
        }
        12% {
            visibility: hidden;
        }
        13% {
            visibility: visible;
        }
        100% {
            visibility: hidden;
        }
    }
}
