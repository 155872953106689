@import "../../constants.scss";

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 4rem 10px;
  .link {
    display: block;
    padding: 10px 20px;
    color: $White;
    text-align: center;
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    background-color: $mainColor;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 500;
    width: 300px;
    &:hover {
      background-color: $SecondaryBlue;
    }
    &.incident {
      background-color: $Red;
      margin-top: 6.75rem;
      &:hover {
        transform: scale(1.05);
        // opacity: 0.8;
        transition: 0.3s;
      }
    }
  }
}
