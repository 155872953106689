@import "../../../constants.scss";

.resultsTable {
  min-width: 800px; //Лучше меньше не задавать поскольку в начинает не помещаться в editRow AthleteName
  border: 1px solid $mainColor;
  // margin-right: 20px;
  // padding-right: 20px;
  .verstId {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
  }
  .name {
    width: 450px;
  }
  tr {
    &.header {
      border: 1px solid #118ab2;
    }
    th {
      background-color: #118ab2;
      height: 32px;
      border-left: 1px solid white;
      color: white;
      &:first-of-type {
        border-left: none;
      }
    }
    td {
      border: 1px solid $secondaryColor;
      padding-left: 10px;
    }
    &:global(.green) {
      background-color: #d8ead2;
    }
    &:global(.yellow) {
      background-color: #f3f1ac;
    }
    &:global(.red) {
      background-color: #f4cccc;
    }
  }
}
