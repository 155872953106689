@import "../../../../constants.scss";

.data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .between {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    div.item {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .dotted {
      border-bottom: 1px dotted $mainColor;
    }
  }
}
