@import "../../../constants.scss";
.counter {
  font-size: 1.25rem;
  font-weight: bold;
  color: forestgreen;
  width: 50px;
  margin-left: 1rem;
  &.danger {
    color: $Red;
  }
}
