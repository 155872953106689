.id {
  min-width: 150px;
  min-height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  color: #ccc;
  padding-left: 10px;
  &.value {
    color: #333;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}
