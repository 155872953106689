.name {
  width: 400px;
  height: 20px;
}
.namePlaceholder {
  color: #bbb;
  opacity: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .namePlaceholder {
    opacity: 1;
  }
}
