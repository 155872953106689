@import "../../../constants.scss";

.role {
    min-width: 280px;
    padding-right: 10px;
    padding-left: 10px;
}
.id {
    width: 400px;
    height: 36px;
}

.name {
    width: 400px;
    .nameContent {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 8px;
        gap: 5px;
    }

    .volunteeringCount {
        justify-self: flex-end;
        cursor: pointer;
        border-radius: 3px;
        color: white;
        margin-right: 5px;
        margin-left: auto;
        background-color: $mainColor;
        padding: 2px 10px;
    }

    .isAdultFlag {
        justify-self: flex-end;
        cursor: pointer;
        border-radius: 3px;
        color: white;
        margin-right: 5px;
        margin-left: auto;
        background-color: $Red;
        padding: 2px 5px;
    }
}

@media (max-width: 758px) {
    .name,
    .role,
    .id {
        width: 100%;
        display: flex;
        align-items: center;
        height: 36px;
        padding: auto 20px;
    }
    .currentRow {
        border: 2px solid $mainColor !important;
        border-bottom: 1px solid $mainColor !important;
    }
}

tr.currentRow {
    border: 2px solid $mainColor;
}

tr.warn {
    color: red;
}

tr.error {
    color: red;
}
