.wrapper {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 10px;
  :global(.ant-picker) {
    width: 100px;
  }
}
