@import "../../../constants.scss";

.currentRow {
  border: 2px solid $mainColor;
  height: 32px;
  &:global(.green) {
    background-color: $LightGreen;
  }
  &:global(.yellow) {
    background-color: $Yellow;
  }
  &:global(.red) {
    background-color: $Red;
  }
  .position {
    min-width: 80px;
    text-align: center;
  }
  .result {
    min-width: 100px;
  }
  // .id {
  //   min-width: 200px;
  // }
  .name {
    // min-width: 450px;
    width: 100%;
    .nameContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-right: 10px;
      .buttons {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 5px;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        @media (max-width: 576px) {
          font-size: 24px;
          gap: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
