@import "../../../../constants.scss";

.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    padding: 0.5em 1em;
    margin-bottom: 15px;

    border-radius: 5px;
    background-color: $Red;

    &:hover {
    opacity: 0.7;
    }
}

.header {
    color: white;
    margin-top: .2em;
    margin-bottom: .2em;
}

.upcircle {
    color: #ffffff;
}

.buttonReg {
    width: 100%;
    padding: 0.2em 1em;
    font-size: 1.25rem;
    border-radius: 5px;
    background-color: $Red;
    color: white;
}

/* .buttonarrow:hover {
    opacity: 0.8;
} */
