.wrapper {
  padding: 30px;
  button + button {
    margin-left: 20px;
  }
  ul {
    list-style: disc;
  }
  .eventData {
    font-size: 24px;
    padding: 20px 0px;
  }
  table {
    border-collapse: collapse;
  }
  td,
  th {
    width: 300px;
    border: 1px solid gray;
    padding: 5px 10px;
    font-size: 20px;
  }
  td.position,
  th.position {
    width: 45%;
  }
  td.name,
  th.name {
    width: 55%;
  }
}

@media print {
  .wrapper {
    margin: 10px;

    .closeButton {
      display: none;
    }
    table {
      width: 100%;
    }
    td,
    th {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
