.wrapper {
  font-size: 16px;
  &.default {
    color: #555;
  }
  &.warning {
    color: orange;
  }
  &.error {
    color: red;
  }
  &.success {
    color: green;
  }
  &.large {
    font-size: 1.25rem;
  }
  &.default {
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    line-height: 1em;
  }
}
