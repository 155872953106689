@import "../../../constants.scss";

.delete {
  color: $Red;
  font-size: 22px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
  &.onRed {
    color: yellow;
  }
  @media (max-width: 576px) {
    font-size: 32px;
  }
}
