@import "../../../constants.scss";
.wrapper {
  margin-top: 1rem;
  padding: 10px;

  background: lightblue;
  border-radius: 0.5rem;
  section + section {
    margin-top: 1rem;
  }
  section h2 {
    text-decoration: underline;
    font-size: 1.25rem;
    line-height: 1rem;
    opacity: 0.9;
  }
  @media (max-width: 370px) {
    // margin: 3rem -10px 1rem -10px;
    border-radius: 0;
    .name,
    .phone {
      flex-direction: column;
    }
  }
  .name,
  .phone {
    margin-top: 0;
    font-size: 24px;
    // display: flex;
    // &:first-child {
    //   border: 1px solid red;
    //   width: 30%;
    // }
    // justify-content: space-between;
    button {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
  .call {
    display: block;
    margin-top: 2rem;
    background-color: $Blue;
    color: white;
    font-size: 1.25rem;
    padding: 0.5em 1em;
    border-radius: 0.5rem;
    text-align: center;
    &:hover {
      opacity: 0.9;
    }
  }
}
