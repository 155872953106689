.inputs {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  input[type="checkbox"] + label {
    margin-left: 10px;
  }
  @media (max-width: 550px) {
    flex-wrap: wrap;
  }
}

.checkboxExtraEvent,
.checkboxPromiseEvent,
.timeSelect {
  font-size: 16px;
}

.checkboxPromiseEvent {
  margin-left: 10px;

  @media (max-width: 430px) {
      margin-left: 0;
    }
}

.checkboxLabel {
  font-size: 16px;
}
