.nameInputWrapper {
  display: flex;
  gap: 7px;
  align-items: center;
  min-width: 360px;
  width: 100%;
  .nameInput {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .nameInputWrapper {
    min-width: 100px;
    width: 100%;
  }
}
