.wrapper {
  padding: 2rem 10px 0 10px;
  .eventName {
    max-width: 350px;
  }
  .links {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    a {
      margin-bottom: 10px;
    }
    @media (min-width: 576px) {
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
  }
  .resultLink {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  }
}
