.idInputWrapper {
  display: flex;
  gap: 3px;
  align-items: center;
  min-width: 150px;
  .idInput {
    width: 100%;

    span:global(.ant-select-selection-search) {
      padding-left: 12px;
      &:before {
        position: absolute;
        top: 4px;
        left: 0px;
        content: "A";
        display: inline;
        visibility: visible;
        z-index: 100;
      }
    }

    span:global(.ant-select-selection-placeholder) {
      padding-left: 12px;
    }
  }
}
@media (max-width: 768px) {
  .idInputWrapper {
    min-width: 100px;
    width: 100%;
  }
}
