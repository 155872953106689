@import "../../constants.scss";

/* table {
  width: 100%;
  border-collapse: collapse;
}
tr:nth-of-type(odd) {
  background: var(--bg-color);
}

td,
th {
  border: 1px solid var(--border-color);
  padding: 6px;
  text-align: left;
} */

table.volunteersTable {
  // max-width: 100%;
  // min-width: 800px;
  width: 100%;
  border-collapse: collapse;
  // border: 1px solid $mainColor;

  th {
    background-color: $lightColor;
    border: 1px solid $secondaryColor;
    color: $mainColor;
  }
  td {
    border: 1px solid $secondaryColor;
    padding-left: 10px;
  }
}

@media (max-width: 758px) {
  table.volunteersTable {
    display: block;
    // Убрал отсюда td так как в другом месте они display flex для выравнивания.
    thead,
    tbody,
    th,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid $mainColor;
      border-bottom: none;
    }
    tr:last-of-type {
      border-bottom: 1px solid $mainColor;
    }

    td {
      /* Behave like a row */
      border: none;
      border-bottom: 1px dotted $secondaryColor;
      position: relative;
      height: 36px;
      /* padding-left: 12%; */
    }
    tr:nth-of-type(odd) {
      background: rgba($SecondaryBlue, 0.1);
    }
    td:nth-of-type(3) {
      border-bottom: none;
    }
  }
  /* 
    td:before {
      Now like a table header
      position: absolute;
      Top/left mimic padding
      top: 6px;
      left: 6px;
      width: 10%;
      padding-right: 10px;
      white-space: nowrap;
    }
    td:nth-of-type(1):before {
      content: "Поз";
    }
    td:nth-of-type(2):before {
      content: "ID";
    }
    td:nth-of-type(3):before {
      content: "Имя";
    } */
}
