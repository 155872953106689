@import "../../constants.scss";

.wrapper {
    max-width: 400px;
    min-width: 330px;
    min-height: 150vh;
    margin: 0 auto;
    padding: 0 10px;

    // background: red;
    .iceContact {
        margin-top: 50px;
        h2 {
            margin-bottom: 0;
            text-align: center;
        }

        @media (min-width: 768px) {
            padding-top: 3rem;
            min-height: 100vh;
        }

        .inputs {
            margin-top: 1rem;

            // Крупный шрифт в полях ввода
            :global(.ant-select-selector) {
                height: 3.5rem;
                display: flex;
                align-items: center;

                :global(.ant-select-selection-placeholder) {
                    font-size: 1.25rem;
                }

                :global(.ant-select-selection-item) {
                    display: flex;
                    align-items: center;
                    height: 3.5rem;
                    font-size: 1.25rem;
                }
            }

            .search {
                margin-top: 0.8rem;
                min-width: 320px;

                :global(.ant-select-selector) {
                    display: flex;
                    align-items: center;
                    height: 3.5rem;
                    font-size: 1.25rem;

                    :global(.ant-select-selection-search) {
                        display: flex;
                        align-items: center;
                    }
                }

                p {
                    margin-bottom: 1px;
                }
            }
        }

        button.getContact {
            margin-top: 1rem;
            width: 100%;
            padding: 0.5em 1em;
            font-size: 1.25rem;
            border-radius: 5px;
            background-color: $Red;
            color: white;

            &:hover {
                opacity: 0.7;
            }
        }
    }

}

.emergencyModal {
    h2 {
        color: red;
        margin-right: 1rem;
    }

    :global(.ant-modal-footer) {
        text-align: center;

        :global(.ant-btn.ant-btn-lg) {
            width: auto;
            height: auto;
            margin: 0.5rem 1.25rem;
            font-size: 1.5rem;
            padding: 0.8rem 1.5rem;
            line-height: 1;
        }
    }
}