.wrapper {
    margin-top: 50px;
    .controlGroup {
        margin-top: 1rem;
        .incidentReportTextarea {
            width: 100%;
        }
    }
    .spinner {
        margin-left: 2rem;
    }
}
