.wrapper {
  border: 1px solid red;
  background-color: rgba(red, 0.05);
  padding: 1rem;

  margin-top: 1rem;
  color: red;
  ul {
    margin-left: 1rem;
  }
}
