@import "../../../constants.scss";

.link {
  color: #6c757d;
  font-size: 1rem;
  padding-left: 10px;
  padding-bottom: 8px;
  &:hover {
    color: $Red;
    text-decoration: underline;
  }
}
