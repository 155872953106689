@import "../../../constants.scss";

.wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  font-size: 1rem;

  a {
    color: #6c757d;
    padding: 10px;
    transition: all 0.2s ease-in;
    border-radius: 5px;

    &:hover {
      color: #343a40;

      transform: translateY(-3px);
      transition: all 0.2s ease-in;
      background-color: rgba(#6c757d, 10%);
    }
  }
  .active {
    color: $Red;
    pointer-events: none;
    cursor: auto;
    // &:hover {
    //   color: #1890ff;
    // }
  }
  .danger {
    background-color: $Red;
    color: white;
    &:hover {
      background-color: $Red;
      color: white;
    }
  }
}
@media (max-width: 768px) {
  .wrapper {
    width: 100%;
    height: fit-content;
    z-index: 3;
    gap: 0px;
    flex-direction: column;
    a.danger {
      border-bottom: none;
    }
  }
}
