@import "../../constants.scss";

.files-upload {
  .event-name {
    width: 260px;
  }
  :global(.ant-select.ant-select-single.ant-select-show-arrow) {
    width: 260px;
  }
  :global(.ant-picker) {
    width: 200px;
  }
  .drop-area {
    h2 {
      margin-bottom: 0px;
    }
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 2px dashed $mainColor;
    padding: 10px;
    margin-bottom: 20px;
    .files {
      margin-top: 20px;
    }
    .file-display {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      .delete-span {
        color: red;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
      }
    }
    input[type="file"] {
      display: none;
    }
    label {
      color: white;
      width: fit-content;
      display: flex;
      align-items: center;
      height: 40px;
      background-color: $mainColor;
      color: white;
      font-size: 16px;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 10px;
      cursor: pointer;
      &:hover {
        background-color: lighten($mainColor, 10%);
      }
    }
  }
  .ant-btn.ant-btn-primary {
    background-color: $oppositeColor;
    border: 1px solid $oppositeColor;
    font-weight: 500;
    &:hover {
      background-color: lighten($oppositeColor, 10%);
    }
  }
  .drop-error-message {
    color: red;
    font-size: 16px;
  }
}

.results-wrapper {
  .results-event {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  .table-buttons {
    display: flex;
    margin-bottom: 50px;
    margin-top: 20px;
    gap: 10px;
    .ant-btn.ant-btn-primary {
      background-color: $oppositeColor;
      border: 1px solid $oppositeColor;
      font-weight: 700;
      &:hover {
        background-color: lighten($oppositeColor, 10%);
      }
    }
    .ant-btn.ant-btn-default {
      &:hover {
        border: 1px solid $oppositeColor;
        color: $oppositeColor;
      }
    }
  }
}
