@import "../../../constants.scss";

.wrapper {
  min-width: 80px;
  width: 80px;
  min-height: 20px;
  input {
    width: 100%;
    &:focus {
      border: 1px solid $SecondaryBlue;
      outline: none;
    }
  }
}
