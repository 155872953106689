.wrapper {
  padding: 1rem;
  .eventName {
    margin-bottom: 1rem;
  }
  @media (min-width: 450px) {
    .eventName {
      max-width: 300px;
    }
  }
}
