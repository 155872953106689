@import "../../../constants.scss";

.resultRow {
  height: 32px;
  .position {
    min-width: 80px;
    text-align: center;
  }
  .result {
    min-width: 100px;
  }
  /*   .id {
    min-width: 200px;
    width: 200px;
  } */
  .name {
    // min-width: 450px;
    width: 100%;
  }
  // &:global(.green) {
  //   background-color: rgba(#06d6a0, 0.5);
  // }
  // &:global(.yellow) {
  //   background-color: #ffd166;
  // }
  // &:global(.red) {
  //   background-color: #ef476f;
  // }
}
