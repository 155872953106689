@import "../../constants.scss";

.container {
  display: flex;
  padding-top: 3rem;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: center;
  @media (max-width: 800px) {
    justify-content: flex-start;
  }
  .wrapper {
    padding-bottom: 20px;
    width: fit-content;
    @media (max-width: 800px) {
      width: 100%;
      // padding-right: 15px;
      .scrollWrapper {
        overflow-x: auto;
      }
    }
    .head {
      display: flex;

      justify-content: space-between;
      margin-bottom: 10px;
      .event {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .eventName {
          max-width: 300px;
          min-width: 240px;
        }
        // :global(.ant-select.ant-select-single.ant-select-show-arrow) {
        //   width: 260px;
        // }
        // :global(.ant-picker) {
        //   width: 200px;
        // }
      }
      .undoRedo {
        display: flex;
        gap: 10px;
      }
    }
  }
}

:global(.results-modal) {
  margin-left: 0px;
  // width: 520px;
  @media (max-width: 380px) {
    max-width: 360px;
    :global(.ant-modal) {
      width: 100% !important;
      margin-left: 10px;
    }
  }
  @media (max-width: 576px) {
    max-width: 520px;
    :global(.ant-modal) {
      width: 100% !important;
      margin-left: 10px;
    }
  }
}
